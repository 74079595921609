import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import msiLogo from '../images/logo-small.jpg'

const Heading = styled.h1`
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: bold;
`

const SubHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <img src="https://res.cloudinary.com/mystay-international/image/upload/c_limit,h_250,w_280/v1528171008/France-Homestay-Network-logo-FINAL.png" alt="France Homestay network"/>
    <Heading style={{ marginTop: '1rem' }}>Part of MyStay International (MSI) Group</Heading>
    <SubHeading>For any enquiries, please <a href="https://mystayinternational.com/contact-us/" target="_blank">contact</a> MSI</SubHeading>
    <div style={{ display: 'table', margin: 'auto', borderTop: '1px solid black', width: '100%', paddingTop: '1rem' }}>
      <div style={{ padding: '1rem 4rem', textAlign: 'center' }}>
        <img 
          src={msiLogo}
          alt="Australian Homestay Network"
          style={{ maxHeight: '135px' }}
        />
      </div>
    </div>
  </Layout>
)

export default IndexPage
